body{
	margin:0; 
	font-family: -apple system, BlinkMacSystemFome, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", san-serif;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing: grayscale;

}

code{
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
