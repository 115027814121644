*{
 font-family:"Helvetica Neue", Helvetica, Arial, sans-serif
}

form{
	font-size:90%;
}
.header{
	background-color:#1f6e91; 
	color:white;
	width: 100%; 
	height: 15%; 
	top:0px; 
	left:0px;
	position:fixed;
}

h1{
	color: white;
	padding:20px 50px;
	display:inline-block;
}

.navBar {
	position:fixed;
	top:50px;
	right:500px;
	white-space:nowrap;
}

.navBar li{
	margin-right:260%;
}


.sub_navBar li{
	margin-right:15%;
}

ul {
	list-style-type:none;
}

ul li{
	display:inline;
	padding-left:7%;

}
.desc{
	color:white;
	text-decoration:none;
}

.desc:hover{
	text-decoration:none;
	color:#ef6101;

}

.link {
	color:white;
	text-decoration:none;
}

.link:hover{
	color:#EF6101;
	text-decoration:none;
}

.view{
	display:inline;
	padding: 0 10%;
	color:#c0bebf;
}

.body {
	position:fixed;
	height: 75%;
	width:75%;
	top:15%;
	right:0px;
}


.sidebar{
	background-color:#EAE8E9;
	height:85%;
	position:fixed;
	min-width:250px;
	width:20%;
	left:0px; 
	top:15%;
}

.form {
	position: fixed;
	left: .5%;
	top:17%;
	width:19%;
}

.setting>input .setting>button, .setting>select{
	display:block;
}

.slider-text{
	display:inline-block;
}
.slider{
	width: 73%;
}

.text{
	width: 20%;
	height: 30px;
	margin-left: 5%;
}

.long_text{
	width:80%;
}

.setting{
	margin-top:5%;
}


#request{
	position:fixed;
	bottom:5%;
	margin-left:4%;
}

.submit{
	margin-left: 5%;
}


#display_setting{
	position:fixed;
	bottom:4%;
	left:25%;
	width:100%;
}

.pref{
	display:inline;
	margin-left:5%;
	white-space:nowrap;
}

.pref label {
	padding-left:1%;
}

.three{
	position:fixed;
	top:25%;
	left:20%;
	height:70%;
}

#input{
	margin:20% 5%;
	display:inline-block;
	font-size:75%;
}

#input button{
	margin:10% 20%;
}

#response{
	position:fixed;
	top:40%;
	left:35%;
}

#scatter_plot{
	position:fixed;
	top:20%;
	left:30%;
	height:60%;
	width:60%;
}

.set {
	display: inline;
	white-space:nowrap;
	color:white;
	margin-right:5%;
	border:1px solid #1f6e91;
	background:#1f6e91;
	padding:2%;
	border-radius:5%;
}

.set:hover{
	color:#EF6101; 
} 

.hidden{
	display:None;
}

.visibile{
	display:block;
}

.label{
	font: 12px sans-serif;
}

#histogram{
	position:fixed;
	height:70%;
	width:75%;
	right:0px;
	top:15%;
}

#population{
	height:500px;
	width:200px;
}

#message{
	position:fixed;
	top:20%;
	left: 25%;
}

#i{
	background:grey;
	color:white;
	text-align:center;
	height:35px;
	width:35px;
	border-radius:50%;
	border:2px solid black;
}
#info{
	border: 1px solid black;
	width:70%;
	padding:3%;
	height:10%;
	background:white;
}
